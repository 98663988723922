<template>

    <section class="py-6 pl-lg-4 pl-md-0">

        <h2 class="font-weight-bold mb-4">Follow on Twitter</h2>
        <twitter-post></twitter-post>
    </section>
</template>

<script>
import TwitterPost from '@/views/components/TwitterPost.vue';

export default {
    components: {
        TwitterPost
    }
};
</script>
