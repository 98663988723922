<template>
    <div class="home">
        <Banner/>

        <welcome-section>
            <template #header>
                <h2 class="poppins-bold mb-4">WELCOME TO THE WEBSITE OF REACHE NORTH WEST</h2>
            </template>
            <template #body>
                <p class="mb-0 pr-lg-5">
                    Returning refugee and asylum seeker doctors, nurses and allied healthcare workers to their
                    professions in the NHS. Over 500 healthcare staff returned to NHS employment. Inhouse clinical and
                    language tutors, detailed OET & PLAB curriculum with high pass rates, dedicated pastoral, housing
                    and mental health support
                </p>
                <br>
                <p>
                    We put our members first. If you are based in the Northwest of England, are a refugee or asylum
                    seeker, please contact us for more information.
                </p>
            </template>
            <template #media>
                <iframe src="https://www.youtube.com/embed/_bnnmWYI0lM" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
            </template>
        </welcome-section>

        <!-- Service and Social Links -->
        <section class="service-bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-12 mx-0 m-0 main-section">
                        <service-section></service-section>
                    </div>
                    <div class="col-lg-4 col-12 mx-0 m-0 sub-section">
                        <social-comments-section></social-comments-section>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script>
import Banner from '@/views/components/Banner';
import WelcomeSection from '@/views/components/WelcomeSection.vue';
import ServiceSection from '@/views/components/ServiceSection.vue';
import SocialCommentsSection from '@/views/components/SocialCommentsSection.vue';

export default {
    name: 'Home',
    components: {
        Banner,
        WelcomeSection,
        ServiceSection,
        SocialCommentsSection
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll (event) {
            console.log('scroll', event);
            console.log(window.scrollY);
            // event.target.classList.add('nav-lg-white');
            const navContainEl = document.querySelector('.nav-container');
            if (window.scrollY > 50 && !navContainEl.classList.contains('nav-lg-white')) {
                navContainEl.classList.add('nav-lg-white');
            } else if (window.scrollY < 50 && navContainEl.classList.contains('nav-lg-white')) {
                navContainEl.classList.remove('nav-lg-white');
            }
        }
    }
};

</script>
<style scope>
.main-section {
    background-color: #e5eff9;
}

.sub-section {
    background-color: #f7fafd;
}
</style>
