<template>
    <div class="banner-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <p>
                        Funded by Health Education England to support refugee and asylum-seeking health care
                        professionals
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Banner'
};
</script>

<style scoped>
img {
    width: 100%;
    height: auto;
}

.banner-area {
    font-family: 'poppins-semibold', serif;
    font-size: 1.8rem;
    background: url('../../assets/img/banner.png');
    background-position: left;
    background-size: cover;
    min-height: 100vh;
    text-align: left;
    display: flex;
    align-items: center;
}

@media (max-width: 992px) {
    .banner-area {
        font-size: 1.562rem;
        text-align: center;
        background-position: 80%;
        padding: 0;
        background-repeat: no-repeat;

    }

    .banner-area p {
        width: 100%;
    }

}
@media (min-width: 1024px) and (max-width: 1399px) {
    .banner-area{
        background-position: 80%;
    }
}
</style>
